import React from 'react';
import styled from 'styled-components';
import loadable from "@loadable/component"
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import '../../styles/globals.css';
import { Link, graphql } from 'gatsby';
import { MetaComponent } from '../../components';
import {
  Button,
  SassyText,
  Section,
  Text,
  Divider,
  SectionWrapper,
  SubTitle,
} from '../../components/core';
import { useMixpanel } from '../../context/MixpanelContext';

import Bullet from '../../assets/images/bullet.svg';
import HeroSection from '../../components/business-types/HeroSection';
import AboutBSUS from '../../components/business-types/AboutBSUS';
import { TestimonialCard, TestimonialContainer, TestimonialImgContainer } from '../../components/MLPTestimonials';
import { LeadFormProvider } from '../../context/LeadFormContext';

const Footer = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../../components/LeadForm"));
const Navbar = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Navbar,
});

const Coverage = loadable(() => import("../../components/Coverage"));
const HelpSection = loadable(() => import("../../components/HelpSection"));
const VideoSection = loadable(() => import("../../components/VideoSection"));

const Shop = () => {
  const {t} = useTranslation(['bsus', 'common'])
  const mixpanel = useMixpanel()
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (mxEventName = "") => {
    if (mxEventName) mixpanel.track(mxEventName)
    handleQuoteModalOpen()
  }

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <MetaComponent
        title="Shop Insurance - Verak"
        description="Protect your business from pressing risks like fire, lightning, explosion and natural disasters. Get a quote."
        image="bsus"
        url="fire/bsus"
      />
      <Navbar active="products" />
      <HeroSection 
        pageName="shop"
        content={(
          <>
            All kinds of shops- kirana, general, grocery, garment and more- face some common risks such as:
            <ul style={{listStylePosition: "inside"}}>
              <li>Property and furniture damage due to fire or flood</li>
              <li>Risks of theft or burglary</li>
              <li>Intentional damage by third parties</li>
              <li>Destruction due to riots and strikes</li>
              <li>And more</li>
            </ul>
          </>
        )}
      />

      <Section background="rgba(255, 148, 80, 0.1)" style={{padding: "20px 0"}}>
        <SectionWrapper px="0">
          <Text fontSize="18px" lineHeight="30px" mfontSize="16px" mlineHeight="24px" style={{textAlign: "center"}}>
            That is why we offer Vyapar Suraksha (technically called Bharat Sookshma Udyam Suraksha)- the cheapest and best way to protect your shop from common risks.
          </Text>
        </SectionWrapper>
      </Section>

      <Section background="#fff">
        <SectionWrapper desktopStyles={{paddingBottom: "0"}}>
          <SubTitle fontSize="38px" lineHeight="68px" mfontSize="26px" mlineHeight="46px">
            Hear from <SassyText>our customers</SassyText>
          </SubTitle>
          <ModifiedTestimonialContainer>
            <TestimonialCard>
              <TestimonialImgContainer>
                <StaticImage 
                  src="../../assets/images/testimonial_romil.webp"
                  alt="testimonial_romil"
                  loading="lazy"
                  width={249}
                  height={249}
                  style={{borderRadius: "10px"}}
                />
              </TestimonialImgContainer>
              <Text color="#000000" fontWeight="bold" fontSize="16px" mfontSize="14px" style={{marginTop: '0', textAlign: 'center'}}>Romil S</Text>
              <Text color="#4D4D4D" fontSize="14px" style={{marginTop: '0', textAlign: 'center'}} mfontSize="14px">Furniture Retailer (Mumbai)</Text>
              <Text color="#4D4D4D" fontSize="14px" mfontSize="12px" lineHeight="22px" style={{marginTop: "18px", textAlign: "center"}}>
                I wanted to buy property insurance as I have seen two cases of fire in the last 15 years, and Mumbai <strong>is prone to calamities like floods.</strong> The Verak sales rep I spoke to was knowledgeable about the product, and the online <strong>purchase journey was smooth and hassle-free.</strong>
              </Text>
            </TestimonialCard>
            <TestimonialCard>
              <TestimonialImgContainer>
                <StaticImage 
                  src="../../assets/images/testimonial_jayesh_b.webp"
                  alt="testimonial_jayesh_b"
                  loading="lazy"
                  width={249}
                  height={249}
                  style={{borderRadius: "10px"}}
                />
              </TestimonialImgContainer>
              <Text color="#000000" fontWeight="bold" fontSize="16px" mfontSize="14px" style={{marginTop: '0', textAlign: 'center'}}>Jayesh B.</Text>
              <Text color="#4D4D4D" fontSize="14px" style={{marginTop: '0', textAlign: 'center'}} mfontSize="14px">Pharmaceutical retailer in Pune</Text>
              <Text color="#4D4D4D" fontSize="14px" mfontSize="12px" lineHeight="22px" style={{marginTop: "18px", textAlign: "center"}}>
                <strong>Verak ka WhatsApp model bahut acha laga mujhe.</strong> They were very quick in their replies and were readily responsive to my queries, which I liked a lot. <strong>There was no delay in the process and everything went in a smooth manner which was only possible because of Verak’s WhatsApp feature.</strong>
              </Text>
            </TestimonialCard>
            <TestimonialCard>
              <TestimonialImgContainer>
                <StaticImage 
                  src="../../assets/images/testimonial_ajai_n.webp"
                  alt="testimonial_ajai_n"
                  loading="lazy"
                  width={249}
                  height={249}
                  style={{borderRadius: "10px"}}
                />
              </TestimonialImgContainer>
              <Text color="#000000" fontWeight="bold" fontSize="16px" mfontSize="14px" style={{marginTop: '0', textAlign: 'center'}}>Ajai N.</Text>
              <Text color="#4D4D4D" fontSize="14px" style={{marginTop: '0', textAlign: 'center'}} mfontSize="14px">Imitation Jeweller in Nashik</Text>
              <Text color="#4D4D4D" fontSize="14px" mfontSize="12px" lineHeight="22px" style={{marginTop: "18px", textAlign: "center"}}>
                I always wanted to buy business insurance because there has been a <strong>flood in my area</strong> before. I did not want to get it through an agent, so as soon as I came across Verak, I started the process.  It was all <bold>very smooth and hassle-free</bold> as I was able to make the <bold>purchase</bold> within hardly <strong>2 hours</strong> of Verak’s call. 
              </Text>
            </TestimonialCard>
          </ModifiedTestimonialContainer>
        </SectionWrapper>
      </Section>

      <AboutBSUS />
      <VideoSection videoLink="https://www.youtube.com/embed/RhkrAvQo27E" style={{paddingTop: "80px"}} />
      <Coverage type="BSUS" enableTranslations={false} />

      <Section background="#ffffff">
        <SectionWrapper>
          <SubTitle>
            <Trans i18nKey="WhyBSUS.Title">
              Why opt for a <SassyText> BSUS </SassyText> policy?
            </Trans>
          </SubTitle>
          <Left>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px">
                    <Bullet className="bullet" />
                    {t("WhyBSUS.Protection.Title")}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                    
                  >
                    {t("WhyBSUS.Protection.Desc")}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px">
                    <Bullet className="bullet" />
                    {t("WhyBSUS.RiskCoverage.Title")}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                    
                  >
                    {t("WhyBSUS.RiskCoverage.Desc")}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px">
                    <Bullet className="bullet" />
                    {t("WhyBSUS.AdditionalCovers.Title")}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                    
                  >
                    {t("WhyBSUS.AdditionalCovers.Desc")}
                  </Text>
                </li>
              </ul>
            </Container>
            <Text fontSize="16px" mfontSize="16px" >
              <Trans i18nKey="WhyBSUS.LearnAboutBSUS">
                Learn about
                <strong> Bharat Sookshma Udyam Suraksha (BSUS) </strong>
                in detail 
                <a href="https://blog.verak.in/product-explainers-bharat-sookshma-udyam-suraksha-bsus-policy-amp/">
                  <SassyText >HERE</SassyText>
                </a>
              </Trans>
            </Text>
            <Text
              fontSize="16px"
              style={{ margin: '40px 0px' }}
              mfontSize="16px"
              
            >
              <Trans i18nKey="WhyBSUS.OtherOptionsText">
                If your business’s total insurable assets at 
                <strong> any one location are worth more than ₹5 Crore </strong> ,
                you may want to consider opting for a 
              </Trans>
              <Link to="/fire/sfsp">
                <SassyText type="secondary" >
                  {t('SFSPFullForm')} (SFSP)
                </SassyText>
              </Link>
              , {t('WhyBSUS.Or')} {t('WhyBSUS.A')}{' '}
              <Link to="/fire/blus">
                <SassyText type="secondary" >
                {t('BLUSFullForm')} (BLUS)
                </SassyText>
              </Link> {''}
              <Trans i18nKey="WhyBSUS.InsurancePolicy">
                Insurance policy
              </Trans>!
            </Text>
            <Button wide label={t('CTALabels.GetAQuote')} onClick={() => handleGetQuoteClick("Clicked-Down - 'Get a Quote' - BSUS LP")} />
          </Left>
        </SectionWrapper>
      </Section>

      <HelpSection />
      <Divider />
      <Footer />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        ns: {
          in: ["bsus", "index", "common", "navbar", "footer", "getQuoteModal", "helpSection", "coverageSection", "videoSection"]
        }, 
        language: {eq: "en"}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


const ModifiedTestimonialContainer = styled(TestimonialContainer)`
    margin-top: 48px;
    margin-bottom: 0;
    gap: 3.5rem;
    @media (max-width: 768px) {
      gap: 1.25rem;
    }
`

const Left = styled.div`
  text-align: left;

  @media screen and (max-width: 768px) {
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: start;
  margin: 20px 0px;

  ul {
    margin-top: 40px;
  }

  .bullet {
    margin-left: -20px;
    margin-right: 35px;
  }

  li {
    color: #999999;
    margin-left: 24px;
    margin-bottom: 24px;
    list-style: none;
  }
`;

export default Shop;
