import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import loadable from "@loadable/component"

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const Text = loadable(() => import("./core"), {
  resolveComponent: (components) => components.Text,
});

const Testimonials = () => {
    const {t} = useTranslation('mlp-testimonials')

    return (
      <TestimonialContainer>
        <TestimonialCard>
          <TestimonialImgContainer>
            <StaticImage 
              src="../assets/images/testimonial_zafar_k.webp"
              alt="testimonial-zafar-k"
              loading="lazy"
              width={249}
              height={249}
              style={{borderRadius: "10px"}}
            />
          </TestimonialImgContainer>
          <Text color="#000000" fontWeight="bold" fontSize="16px" mfontSize="14px" style={{marginTop: '0', textAlign: 'center'}}>{t('Testimonials.0.UserName')}</Text>
          <Text color="#4D4D4D" fontSize="14px" style={{marginTop: '0', textAlign: 'center'}} mfontSize="14px">{t('Testimonials.0.Occupation')}</Text>
          <Text color="#4D4D4D" fontSize="14px" mfontSize="12px" lineHeight="22px" style={{marginTop: "18px", textAlign: "center"}}>
            <Trans 
              i18nKey="Testimonials.0.Text"
              components={{ bold: <strong /> }}
            />
          </Text>
        </TestimonialCard>
        <TestimonialCard>
          <TestimonialImgContainer>
            <StaticImage 
              src="../assets/images/testimonial_vineet_m.webp"
              alt="testimonial-vineet-m"
              loading="lazy"
              width={249}
              height={249}
              style={{borderRadius: "10px"}}
            />
          </TestimonialImgContainer>
          <Text color="#000000" fontWeight="bold" fontSize="16px" mfontSize="14px" style={{marginTop: '0', textAlign: 'center'}}>{t('Testimonials.1.UserName')}</Text>
          <Text color="#4D4D4D" fontSize="14px" style={{marginTop: '0', textAlign: 'center'}} mfontSize="14px">{t('Testimonials.1.Occupation')}</Text>
          <Text color="#4D4D4D" fontSize="14px" mfontSize="12px" lineHeight="22px" style={{marginTop: "18px", textAlign: "center"}}>
            <Trans 
              i18nKey="Testimonials.1.Text"
              components={{ bold: <strong /> }}
            />
          </Text>
        </TestimonialCard>
        <TestimonialCard>
          <TestimonialImgContainer>
            <StaticImage 
              src="../assets/images/testimonial_deepak_k.webp"
              alt="testimonial-deepak-k"
              loading="lazy"
              width={249}
              height={249}
              style={{borderRadius: "10px"}}
            />
          </TestimonialImgContainer>
          <Text color="#000000" fontWeight="bold" fontSize="16px" mfontSize="14px" style={{marginTop: '0', textAlign: 'center'}}>{t('Testimonials.2.UserName')}</Text>
          <Text color="#4D4D4D" fontSize="14px" style={{marginTop: '0', textAlign: 'center'}} mfontSize="14px">{t('Testimonials.2.Occupation')}</Text>
          <Text color="#4D4D4D" fontSize="14px" mfontSize="12px" lineHeight="22px" style={{marginTop: "18px", textAlign: "center"}}>
            <Trans 
              i18nKey="Testimonials.2.Text"
              components={{ bold: <strong /> }}
            />
          </Text>
        </TestimonialCard>
      </TestimonialContainer>
    )
  }

export default Testimonials;

export const TestimonialContainer = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    margin-bottom: 1rem;
  }
`

export const TestimonialCard = styled.div`
  padding: 1.25rem;
  padding-bottom: 1.5rem;
  width: 342px;
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    width: min(270px, 100%);
  }
`

export const TestimonialImgContainer = styled.div`
  margin: auto;
  margin-bottom: 2.5rem;
  width: min(249px, 100%);
  min-width: 200px;
  border-radius: 10px;
  @media (max-width: 768px) {
    margin-bottom: 1.25rem;
    width: 125px;
  }
`